import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const ShieldTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "受注番号", value: "acceptNo" },
  { text: "作業者", value: "userId" },
  { text: "機械名", value: "machineCode" },
  { text: "仕様書No", value: "specificationNo" },
  { text: "品名", value: "itemName" },
  { text: "サイズ", value: "size" },
  { text: "実作業条長(m)", value: "actualWorkingLength" },
  { text: "数量明細(m)", value: "suryoMeisai" },
  { text: "製品異常処置報告", value: "abnormality" },
  { text: "備考", value: "biko" }
] as const;

export class ShieldTable {
  workDate: string;
  updateTs: string | null;
  acceptNo: string | null;
  userId: string | null;
  postCode: string | null;
  machineCode: string | null;
  specificationNo: string | null;
  itemName: string | null;
  size: string | null;
  actualWorkingLength: number | null;
  suryoMeisai: string | null;
  abnormality: string | null;
  biko: string | null;
  denno: number | null;
  gyono: number | null;

  constructor(props: {
    workDate: string;
    acceptNo: string | null;
    userId: string | null;
    postCode: string | null;
    machineCode: string | null;
    specificationNo: string | null;
    itemName: string | null;
    size: string | null;
    actualWorkingLength: number | null;
    suryoMeisai: string | null;
    abnormality: string | null;
    biko: string | null;
    denno: number | null;
    gyono: number | null;
  }) {
    this.workDate = props.workDate;
    this.acceptNo = props.acceptNo;
    this.updateTs = null;
    this.userId = props.userId;
    this.postCode = props.postCode;
    this.machineCode = props.machineCode;
    this.specificationNo = props.specificationNo;
    this.itemName = props.itemName;
    this.size = props.size;
    this.actualWorkingLength = props.actualWorkingLength;
    this.suryoMeisai = props.suryoMeisai;
    this.abnormality = props.abnormality;
    this.biko = props.biko;
    this.denno = props.denno;
    this.gyono = props.gyono;
  }
}

export const ShieldText = {
  workDate: { text: "作業年月日", value: "workDate" },
  serialNo: { text: "シリアル番号", value: "serialNo" },
  postCode: { text: "部署コード", value: "postCode" },
  machineCode: { text: "機械名", value: "machineCode" },
  userId: { text: "作業者", value: "userId" },
  inspectionFlag: { text: "機械点検記録", value: "inspectionFlag" },
  acceptNo: { text: "受注番号", value: "acceptNo" },
  successFlag: { text: "受注番号品 完了", value: "successFlag" },
  assemblyNo: { text: "集合番号", value: "assemblyNo" },
  specificationNo: { text: "仕様書No", value: "specificationNo" },
  itemName: { text: "品名", value: "itemName" },
  size: { text: "サイズ", value: "size" },
  actualWorkingLength: { text: "実作業条長(m)", value: "actualWorkingLength" },
  suryoMeisai: { text: "数量明細(m)", value: "suryoMeisai" },
  shieldType: { text: "種類", value: "shieldType" },
  bobbinConfiguration: { text: "ボビン構成", value: "bobbinConfiguration" },
  twistWireDiameter: { text: "下撚外径(mm)", value: "twistWireDiameter" },
  dice: { text: "ダイス(mm)", value: "dice" },
  gearA: { text: "ギアA", value: "gearA" },
  gearB: { text: "ギアB", value: "gearB" },
  appearance: { text: "外観", value: "appearance" },
  abnormality: { text: "製品異常処置報告", value: "abnormality" },
  biko: { text: "備考", value: "biko" }
} as const;

export class Shield extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  machineCode: string | null;
  userId: string | null;
  inspectionFlag: string | null;
  acceptNo: string | null;
  successFlag: string | null;
  specificationNo: string | null;
  itemName: string | null;
  size: string | null;
  actualWorkingLength: number | null;
  suryoMeisai: string | null;
  shieldType: string | null;
  bobbinConfiguration: string | null;
  twistWireDiameter: number | null;
  dice: number | null;
  gearA: string | null;
  gearB: string | null;
  appearance: string | null;
  abnormality: string | null;
  biko: string | null;
  denno: number | null;
  gyono: number | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: Shield);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: Shield | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof Shield) {
      this.postCode = _props.postCode;
      this.machineCode = _props.machineCode;
      this.userId = _props.userId;
      this.inspectionFlag = _props.inspectionFlag;
      this.acceptNo = _props.acceptNo;
      this.successFlag = _props.successFlag;
      this.specificationNo = _props.specificationNo;
      this.itemName = _props.itemName;
      this.size = _props.size;
      this.actualWorkingLength = _props.actualWorkingLength;
      this.suryoMeisai = _props.suryoMeisai;
      this.shieldType = _props.shieldType;
      this.bobbinConfiguration = _props.bobbinConfiguration;
      this.twistWireDiameter = _props.twistWireDiameter;
      this.dice = _props.dice;
      this.gearA = _props.gearA;
      this.gearB = _props.gearB;
      this.appearance = _props.appearance;
      this.abnormality = _props.abnormality;
      this.biko = _props.biko;
      this.denno = _props.denno;
      this.gyono = _props.gyono;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "SHIELD";
    this.machineCode = null;
    this.userId = null;
    this.inspectionFlag = "0";
    this.acceptNo = null;
    this.successFlag = "0";
    this.specificationNo = null;
    this.itemName = null;
    this.size = null;
    this.actualWorkingLength = null;
    this.suryoMeisai = null;
    this.shieldType = null;
    this.bobbinConfiguration = null;
    this.twistWireDiameter = null;
    this.dice = null;
    this.gearA = null;
    this.gearB = null;
    this.appearance = "0";
    this.abnormality = null;
    this.biko = null;
    this.denno = null;
    this.gyono = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
